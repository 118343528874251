/* eslint-disable no-shadow */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Image } from '../../fc-components/main/components';
import get from 'lodash/get';
import EventModel from '../../../utils/tracking/models';

export const QuickCarousel = ({ list = 'default', slides, arrows }) => {
  const [slide, setSlide] = useState(0);
  const [x0, setX0] = useState(null);
  const [x1, setX1] = useState(null);
  const imageRef = useRef();
  const hedRef = useRef();
  const dekRef = useRef();
  const carouselRef = useRef();

  const fadeOut = () => {
    imageRef.current.classList.remove('loaded');
    imageRef.current.classList.add('loading');

    hedRef.current.classList.remove('loaded');
    hedRef.current.classList.add('loading');

    dekRef.current.classList.remove('loaded');
    dekRef.current.classList.add('loading');
  };

  const fadeIn = () => {
    imageRef.current.classList.remove('loading');
    imageRef.current.classList.add('loaded');

    hedRef.current.classList.remove('loading');
    hedRef.current.classList.add('loaded');

    dekRef.current.classList.remove('loading');
    dekRef.current.classList.add('loaded');
  };

  const handleGalleryReadMoreClick = (arr, position) => {
    const clickEvent = new EventModel(
      `BWI-${get(arr[position], 'hed', 'no headline')}`,
      'ReadMore',
      'click',
      `nav-${get(arr[position], 'hed', 'no headline')}`,
      `${position + 1} | ${arr.length}`
    );

    clickEvent.triggerTrackingEvent(
      'default-click',
      clickEvent.getTrackingObject()
    );
  };

  const handleGalleryAdvanceClick = (
    arr,
    position,
    action = 'click',
    direction
  ) => {
    const clickEvent = new EventModel(
      `${list}-Gallery`,
      'slideshowNavigation',
      `${action}`,
      `${direction} | Slide`,
      `${position + 1} | ${arr.length}`
    );
    clickEvent.triggerTrackingEvent(
      'slide-advance',
      clickEvent.getTrackingObject()
    );

    fadeOut();

    if (direction === 'Backward') {
      setSlide(slide === 0 ? slides.length - 1 : slide - 1);
      setTimeout(() => fadeIn(), 250);
    } else {
      setSlide(slide === slides.length - 1 ? 0 : slide + 1);
      setTimeout(() => fadeIn(), 250);
    }
  };

  // Animation timer
  useEffect(
    () => {
      const interval = setInterval(() => {
        setSlide(slide => (slide === slides.length - 1 ? 0 : slide + 1));

        fadeOut();

        setTimeout(() => {
          fadeIn();
        }, 250);
      }, 5000);

      return () => clearInterval(interval);
    },
    [slide]
  );

  // Set up touch events
  const lock = e => {
    setX0(e.changedTouches[0].clientX);
  };

  const move = e => {
    setX1(e.changedTouches[0].clientX);
  };

  useEffect(() => {
    carouselRef.current.addEventListener('touchstart', lock, false);
    carouselRef.current.addEventListener('touchend', move, false);
  }, []);

  useEffect(
    () => {
      if (x0 > x1) {
        handleGalleryAdvanceClick(slides, slide, 'swipe', 'Forward');
      } else if (x0 < x1) {
        handleGalleryAdvanceClick(slides, slide, 'swipe', 'Backward');
      }
    },
    [x1]
  );

  if (!slides) return false;

  return (
    <div className={`quickCarousel ${list}`} ref={carouselRef}>
      <div className="image" ref={imageRef}>
        <Image image={slides[slide].image} />
      </div>

      <a
        href={slides[slide].url}
        target="_blank"
        onClick={() => {
          handleGalleryReadMoreClick(slides, slide);
        }}
      >
        <div className="hed" ref={hedRef}>
          {get(slides, `[${slide}].hed`, slides[slide].title)}
        </div>
      </a>
      <div
        className="dek"
        ref={dekRef}
        dangerouslySetInnerHTML={{
          __html: get(slides, `[${slide}].dek`, slides[slide].deck)
        }}
      />
      <a
        href={slides[slide].url}
        className="button"
        target="_blank"
        onClick={() => {
          handleGalleryReadMoreClick(slides, slide);
        }}
      >
        Read More
      </a>

      {!arrows && (
        <div className="arrows">
          <div
            className="slide-arrow"
            onClick={() => {
              handleGalleryAdvanceClick(slides, slide, 'Backward');
            }}
          >
            ←
          </div>
          <div
            className="slide-arrow"
            onClick={() => {
              handleGalleryAdvanceClick(slides, slide, 'Forward');
            }}
          >
            →
          </div>
        </div>
      )}

      {arrows &&
        (arrows.left || arrows.left_svg) &&
        (arrows.right || arrows.right_svg) &&
        slides.length > 1 && (
          <div className="arrows">
            <div
              className="slide-arrow"
              onClick={() => {
                handleGalleryAdvanceClick(slides, slide, 'Backward');
              }}
            >
              <Image
                image={arrows.format === 'svg' ? arrows.left_svg : arrows.left}
              />
            </div>
            <div
              className="slide-arrow"
              onClick={() => {
                handleGalleryAdvanceClick(slides, slide, 'Forward');
              }}
            >
              <Image
                image={
                  arrows.format === 'svg' ? arrows.right_svg : arrows.right
                }
              />
            </div>
          </div>
        )}
    </div>
  );
};

QuickCarousel.propTypes = {
  list: PropTypes.string,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      hed: PropTypes.string,
      dek: PropTypes.string,
      url: PropTypes.string
    })
  ).isRequired,
  arrows: PropTypes.arrayOf(PropTypes.string).isRequired
};

QuickCarousel.defaultProps = {
  list: 'default'
};
